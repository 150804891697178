var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column justify-center align-center white fill-height"
  }, [_c('div', [_c('v-app-bar', {
    staticClass: "d-flex align-center justify-center",
    attrs: {
      "absolute": "",
      "app": "",
      "color": "primary",
      "flat": "",
      "height": "110"
    }
  }, [_c('a', {
    staticClass: "text-center pa-5",
    attrs: {
      "href": "#"
    }
  }, [_c('img', {
    staticClass: "max-h-70px",
    attrs: {
      "src": "/media/logos/logo_light_155x30.png",
      "alt": ""
    }
  })])])], 1), _c('div', {
    staticClass: "py-6 pt-15 fill-height flex-grow-1 d-flex flex-column flex-root justify-center align-center"
  }, [_vm.errorMessage ? _c('vue-inline-alert', {
    attrs: {
      "message": _vm.errorMessage,
      "message-type": "error",
      "no-close-btn": ""
    }
  }) : _vm._e(), _vm.errorMessage ? _c('v-row', [_c('v-col', [_c('v-btn', {
    staticClass: "text-capitalize",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.$router.push({
          name: 'dashboard'
        }).catch();
      }
    }
  }, [_vm._v("Go Home")])], 1)], 1) : _vm._e(), !_vm.errorMessage ? [_c('h3', {
    staticClass: "bold-text activate-title"
  }, [_vm._v(" Activating your account, please wait... ")]), _c('span', {
    staticClass: "text-muted bold-text font-size-h4 font-size-h3-lg mt-2"
  }, [_vm._v(" You will be automatically redirected, if not please click "), _c('router-link', {
    staticClass: "text-primary font-weight-bolder",
    attrs: {
      "to": {
        name: 'signin'
      }
    }
  }, [_vm._v("here")])], 1)] : _vm._e()], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }